<template>
  <div class="login-page">
    <div class="all-title">用户注册</div>
    <Login />
  </div>
</template>

<script>
import Login from '@/components/Login/Login'

export default {
  components: {
    Login
  },
  setup() {
    
  },
}
</script>

<style lang="scss" scoped>
.login-page{
  background: #1e1e1e;
  height: 100vh;
}

</style>